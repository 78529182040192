export const BLACK_INDEX = 0;
export const WHITE_INDEX = 7;

export const COLORS = [
  "#000000",
  "#1D2B53",
  "#7E2553",
  "#008751",
  "#AB5236",
  "#5F574F",
  "#C2C3C7",
  "#FFFFFF", // "#FFF1E8",
  "#FF004D",
  "#FFA300",
  "#FFEC27",
  "#00E436",
  "#29ADFF",
  "#83769C",
  "#FF77A8",
  "#FFCCAA",
];

export function textColorFromBG(index) {
  if (
    index === 10 ||
    index === 7 ||
    index === 6 ||
    index === 15 ||
    index === 9 ||
    index === 11
  ) {
    return BLACK_INDEX;
  }
  return WHITE_INDEX;
}

export function randomColorIndex() {
  return 1 + Math.floor(Math.random() * (COLORS.length - 1));
}

export function randomColor() {
  return COLORS[randomColorIndex()];
}

export function setGlobalBackgroundColor(i) {
  document.body.style.backgroundColor = COLORS[i];
}

export function setGlobalTextColor(i) {
  document.body.style.color = COLORS[i];
}

export function setBackgroundBlack() {
  setGlobalBackgroundColor(BLACK_INDEX);
  setGlobalTextColor(textColorFromBG(BLACK_INDEX));
}
