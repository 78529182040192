import { createContext, useState } from "react";
import {
  randomColorIndex,
  setGlobalBackgroundColor,
  setGlobalTextColor,
  textColorFromBG,
  WHITE_INDEX,
} from "../utils/Colors";

export const ThemeContext = createContext({
  color: 0,
  setColor: () => {},
  clasName: "",
  setClassName: () => {},
});

export function ThemeContextProvider(props) {
  const [color, setColor] = useState(0);
  const [className, setClassName] = useState("");

  function _setColor(index) {
    if (index == null) {
      index = randomColorIndex();
    }
    setColor(index);

    let className = "dark";
    if (textColorFromBG(index) === WHITE_INDEX) {
      className = "light";
    }

    setClassName(className);

    setGlobalBackgroundColor(index);
    setGlobalTextColor(textColorFromBG(index));
  }

  return (
    <ThemeContext.Provider
      value={{
        color,
        setColor: _setColor,
        className,
        setClassName,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
