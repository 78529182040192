import { useContext, useEffect } from "react";
import Confetti from "react-confetti";

import Button from "../Button/Button";
import { BLACK_INDEX } from "../../utils/Colors";
import { WindowSizeContext } from "../../utils/windowSize-context";
import { ThemeContext } from "../../utils/theme-context";

import "./GameOver.css";
import { SpeechContext } from "../../utils/speech-context";

export default function GameOver(props) {
  const windowSizeContext = useContext(WindowSizeContext);
  const themeContext = useContext(ThemeContext);
  const speechContext = useContext(SpeechContext);

  useEffect(() => {
    themeContext.setColor(BLACK_INDEX);
    speechContext.speak("Congratulations!");
  });

  return (
    <>
      <Confetti
        width={windowSizeContext.width}
        height={windowSizeContext.height}
      />
      <div className="end-container">
        <h2>Congratulations</h2>
        <h3>You Did It!</h3>
        <Button className="top" onClick={props.onRestart}>
          One More?
        </Button>
      </div>
    </>
  );
}
