import { useContext, useEffect, useState } from "react";
import { DEBUG } from "../../utils/Debug";
import { SpeechContext } from "../../utils/speech-context";
import { ThemeContext } from "../../utils/theme-context";
import useInLandscape from "../../utils/useInLandscape";
import Anim from "../Anim/Anim";
import Icon from "../Button/Icon";

import "./Exercise.css";

export default function Exercise(props) {
  const color = props.color;
  const title = props.title;
  const subtitle = props.subtitle;
  const maxTime = props.maxTime;
  const height = props.height;

  const [timeLeft, setTimeLeft] = useState(props.maxTime);
  const [running, setRunning] = useState(true);

  const themeContext = useContext(ThemeContext);
  const speechContext = useContext(SpeechContext);

  useEffect(() => {
    if (subtitle != null) {
      speechContext.speak(title + ". " + subtitle);
    } else {
      speechContext.speak(title);
    }
  });

  const inLandscape = useInLandscape();
  const nextExercise = props.nextExercise;

  useEffect(() => {
    // This BS is just to prevent a ton of updates
    if (color != null) {
      themeContext.setColor(color);
    } else if (themeContext.color === 0 && color !== 0) {
      themeContext.setColor(color);
    }
  }, [themeContext, color]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!running) {
        return;
      }
      let nextTime = timeLeft - 1;
      setTimeLeft(nextTime);
      if (nextTime >= 1 && nextTime <= 3) {
        speechContext.speak("" + nextTime);
      }
      if (nextTime <= 0) {
        nextExercise();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft, maxTime, nextExercise, running, speechContext]);

  function pause() {
    setRunning(false);
  }

  function resume() {
    setRunning(true);
  }

  function turnOffMic() {
    speechContext.setSpeechEnabled(false);
  }

  function turnOnMic() {
    speechContext.setSpeechEnabled(true);
  }

  // VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS
  const titleContent = <div className="title">{title}</div>;
  const subtitleContent = subtitle && (
    <div className="subtitle">{subtitle}</div>
  );
  const animContent = props.anim != null && props.anim.length > 0 && (
    <Anim
      style={{ marginTop: inLandscape ? "0rem" : "2rem" }}
      filename={props.anim}
      alt={title}
      height={height}
    />
  );
  const timeLeftContent = (
    <div
      onClick={() => {
        if (DEBUG) {
          props.nextExercise();
        }
      }}
      className="time"
    >
      {timeLeft}
    </div>
  );
  const middleContent = (
    <div style={{ marginBottom: inLandscape ? "0" : "5%" }}>
      {inLandscape ? (
        <div className="middle-landscape">
          <div style={{ marginLeft: "1rem", textAlign: "left" }}>
            {timeLeftContent}
            {titleContent}
            {subtitleContent}
          </div>
          <div style={{ marginRight: "1rem" }}>{animContent}</div>
        </div>
      ) : (
        <div className="middle">
          {timeLeftContent}
          {titleContent}
          {animContent}
          {subtitleContent}
        </div>
      )}
    </div>
  );
  // VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS VIEWS
  return (
    <div className="exercise">
      <header className="header">
        <h2 className="header-title">Pico-7 Minute</h2>
        <div>
          <Icon
            onClick={running ? pause : resume}
            className="header-button"
            img={running ? "pause" : "play"}
            alt={running ? "pause" : "resume"}
          />
          <Icon
            onClick={speechContext.speechEnabled ? turnOffMic : turnOnMic}
            className="header-button"
            img={speechContext.speechEnabled ? "volume-on" : "volume-off"}
            alt={speechContext.speechEnabled ? "disable audio" : "enable audio"}
          />
        </div>
      </header>
      {middleContent}
      <h2 style={{ opacity: "0" }} className="header-title">
        Pico-7 Minute
      </h2>
      <footer className="footer">
        {props.exerciseIndex === 12 ? (
          <>
            <p>Last exercise!</p>
          </>
        ) : (
          <>
            <p>{12 - (props.exerciseIndex ?? 0)} exercises left</p>
          </>
        )}
      </footer>
    </div>
  );
}
