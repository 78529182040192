import useInLandscape from "../../utils/useInLandscape";
import useWindowSize from "../../utils/useWindowSize";
import "./Anim.css";

export default function Anim(props) {
  const { width, height } = useWindowSize();
  const isInLandscape = useInLandscape();
  let animHeight = props.height * 2;

  if (isInLandscape) {
    animHeight *= 1.5;
  } else if (width > 600 || height > 600) {
    animHeight *= 2;
  } else if (width > 500 || height > 500) {
    animHeight *= 1.5;
  }

  const filename = props.filename;
  if (filename == null || filename.length === 0) {
    return null;
  }
  return (
    <>
      <img
        style={props.style}
        height={animHeight}
        src={`/animations/${filename}`}
        alt={props.alt}
      />
    </>
  );
}
