import { useState } from "react";
import { SpeechProvider } from "../../utils/speech-context";
import { ThemeContextProvider } from "../../utils/theme-context";
import { WindowSizeProvider } from "../../utils/windowSize-context";

import Start from "../Start/Start";
import Workout from "../Workout/Workout";

import "./App.css";

export default function App() {
  return (
    <ThemeContextProvider>
      <WindowSizeProvider>
        <SpeechProvider>
          <AppInner />
        </SpeechProvider>
      </WindowSizeProvider>
    </ThemeContextProvider>
  );
}

function AppInner() {
  const [started, setStarted] = useState(false);

  function startWorkout() {
    setStarted(true);
  }

  return (
    <main style={{ textAlign: "center" }}>
      {started ? <Workout /> : <Start onStart={startWorkout} />}
    </main>
  );
}
