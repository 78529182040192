import { createContext, useState } from "react";

let spokenAlready = {};

export const SpeechContext = createContext({
  speak: (String) => {},
  speechEnabled: true,
  setSpeechEnabled: (any) => {},
  clearCountDown: () => {},
});

export function SpeechProvider(props) {
  const [speechEnabled, setSpeechEnabled] = useState(true);

  function clearCountDown() {
    spokenAlready = {};
  }
  function speak(text) {
    if (!speechEnabled) {
      return;
    }
    if (spokenAlready[text] === true) {
      return;
    }

    const synth = window.speechSynthesis;
    if (synth == null) {
      return;
    }
    if (!synth.speaking) {
      synth.cancel();
      const utter = new SpeechSynthesisUtterance(text);
      synth.speak(utter);
      spokenAlready[text] = true;
    }
  }

  return (
    <SpeechContext.Provider
      value={{ speak, speechEnabled, setSpeechEnabled, clearCountDown }}
    >
      {props.children}
    </SpeechContext.Provider>
  );
}
