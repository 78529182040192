import { useContext } from "react";
import { ThemeContext } from "../../utils/theme-context";

import "./Button.css";

export default function Button(props) {
  const themeContext = useContext(ThemeContext);

  return (
    <button
      id={props.id}
      className={`${themeContext.className} ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
