import { useEffect, useState } from "react";

const inLandscapeUtil = () => {
  if (typeof window === "undefined") {
    return true;
  }
  const width = window.innerWidth / 1.6;
  const height = window.innerHeight;
  return width > height && window.innerHeight < 700;
};

export default function useInLandscape() {
  const [inLandscape, setInLandscape] = useState(inLandscapeUtil());

  function changeWindowSize() {
    if (inLandscapeUtil()) {
      setInLandscape(true);
    } else {
      setInLandscape(false);
    }
  }

  useEffect(() => {
    changeWindowSize();
    window.addEventListener("resize", changeWindowSize);

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener("change", changeWindowSize);
    } else {
      window.addEventListener("orientationchange", changeWindowSize);
    }

    return () => {
      window.removeEventListener("resize", changeWindowSize);
      window.removeEventListener("change", changeWindowSize);
      window.removeEventListener("orientationchange", changeWindowSize);
    };
  }, []);

  return inLandscape;
}
