import { useContext, useMemo } from "react";
import { BLACK_INDEX, textColorFromBG } from "../../utils/Colors";
import { ThemeContext } from "../../utils/theme-context";
import "./Icon.css";

export default function Icon(props) {
  const themeContext = useContext(ThemeContext);
  const colorName = useMemo(() => {
    if (textColorFromBG(themeContext.color) === BLACK_INDEX) {
      return "black";
    }
    return "white";
  }, [themeContext.color]);
  const imageName = `${props.img}-${colorName}.png`;

  return (
    <img
      onClick={props.onClick}
      className={`icon ${colorName} ${props.className}`}
      src={`images/${imageName}`}
      alt={props.alt}
    />
  );
}
