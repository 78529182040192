import { useContext, useMemo, useState } from "react";
import { SpeechContext } from "../../utils/speech-context";
import Exercise from "../Exercise/Exercise";
import GameOver from "../GameOver/GameOver";
import "./Workout.css";

const EXCERSISES = [
  ["Jumping Jacks", "jumpingJacks.gif", 52],
  ["Wall Sits", "wallsit.gif", 53],
  ["Push Ups", "pushups.gif", 32],
  ["Crunches", "crunches.gif", 22],
  ["Step Ups", "stepups.gif", 71],
  ["Squats", "boxsquats.gif", 56],
  ["Tricep Dips", "tricep-dips.gif", 44],
  ["Planks", "plank.gif", 24],
  ["High Knees", "highknees.gif", 56],
  ["Lunges", "lunges.gif", 55],
  ["Push Ups w/ Rotations", "pushrots.gif", 50],
];

function Workout(props) {
  const warmUpTime = props.warmUpTime ?? 60;
  const exerciseTime = props.exerciseTime ?? 30;
  const restTime = props.restTime ?? 10;

  const screens = useMemo(() => {
    let _screens = [];
    _screens.push({ title: "Warm Up", time: warmUpTime, color: 0 });
    _screens.push({
      title: "Get Ready",
      subtitle: "Starting with, " + EXCERSISES[0][0],
      time: 10,
      color: 0,
    });
    EXCERSISES.forEach((exercise, index) => {
      _screens.push({
        title: exercise[0],
        time: exerciseTime,
        index,
        anim: exercise[1],
        height: exercise[2],
      });
      _screens.push({
        title: "Rest",
        subtitle:
          index + 1 < EXCERSISES.length
            ? "Up next, " + EXCERSISES[index + 1][0]
            : "Up next, side planks",
        time: restTime,
        color: 0,
        index: index + 1,
      });
    });
    _screens.push({
      title: "Side Plank (Left)",
      time: exerciseTime,
      index: 12,
      anim: "side-plank.gif",
      height: 46,
    });
    _screens.push({ title: "Switch Sides", time: 10, color: 0, index: 12 });
    _screens.push({
      title: "Side Plank (Right)",
      time: exerciseTime,
      index: 12,
      anim: "side-plank-r.gif",
      height: 46,
    });
    return _screens;
  }, [warmUpTime, exerciseTime, restTime]);

  const [screenIndex, setScreenIndex] = useState(0);
  const [workoutIndex, setWorkoutIndex] = useState(0);
  const speechContext = useContext(SpeechContext);

  function nextExercise() {
    speechContext.clearCountDown();
    setScreenIndex(screenIndex + 1);
  }

  function restart() {
    setScreenIndex(0);
    setWorkoutIndex(workoutIndex + 1);
  }

  return (
    <>
      {screenIndex < screens.length ? (
        <>
          {
            <Exercise
              key={`${workoutIndex}.${screenIndex}`}
              nextExercise={nextExercise}
              anim={screens[screenIndex].anim}
              height={screens[screenIndex].height}
              exerciseIndex={screens[screenIndex].index}
              color={screens[screenIndex].color}
              title={screens[screenIndex].title}
              subtitle={screens[screenIndex].subtitle}
              maxTime={screens[screenIndex].time}
              onRestart={restart}
            />
          }
        </>
      ) : (
        <GameOver onRestart={restart} />
      )}
    </>
  );
}

export default Workout;
