import { useContext, useEffect } from "react";
import { ThemeContext } from "../../utils/theme-context";
import Button from "../Button/Button";

import "./Start.css";

export default function Start(props) {
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    themeContext.setColor(8);
  });

  return (
    <div className="start-container">
      <h1>Pico-7 Minute Workout</h1>
      <Button id="start-button" onClick={props.onStart}>
        Start Workout
      </Button>
    </div>
  );
}
