import { createContext } from "react";
import useWindowSize from "./useWindowSize";

export const WindowSizeContext = createContext({
  width: 0,
  height: 0,
});

export function WindowSizeProvider(props) {
  const { width, height } = useWindowSize();
  return (
    <WindowSizeContext.Provider value={{ width, height }}>
      {props.children}
    </WindowSizeContext.Provider>
  );
}
