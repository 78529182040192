import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App/App";

import "./index.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBYohBGACNDxtOE7nxV1s_AORqtkfqqjiQ",
  authDomain: "pico-7-minute-workout.firebaseapp.com",
  projectId: "pico-7-minute-workout",
  storageBucket: "pico-7-minute-workout.appspot.com",
  messagingSenderId: "953640533786",
  appId: "1:953640533786:web:04ba52d8037919919676a3",
  measurementId: "G-T1DM76NCSJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
